import { LoadManagementType } from "src/pages/LocationEdit/schema";
import { DisplayText } from "../common/interfaces";

export interface LocationSummaryFilter {
  tenantId: string;

  // TODO once scale is getting to big, implement a S2 or H3 based clustering for Map
  //      and pagination for lists
}

export interface UpsertLocationProps {
  id: string;
  tenantId: string;
  name: string;
  countryCode: string;
  state?: string;
  city: string;
  address: string;
  postalCode: string;
  latitude: number;
  longitude: number;
  contactPoint?: string;
  contactNumber?: string;
  contactEmail?: string;
  activeTariffIdAc?: string;
  activeTariffIdDc?: string;
  tags: Array<string>;
  emi3Name: string;
  ocpiAvailability: string;
  powerConnection: PowerConnection;
  enableAutoRestart: boolean;
  directions?: Array<DisplayText>;
}

export interface LocationSummaryResponse {
  id: string;
  countryCode?: string;
  name: string;
  address: string;
  latitude: number;
  longitude: number;
  ocpiAvailabilityType?: string;
  stations: LocationStationStatus;
  evses: LocationEvseStatus;
  tags: Array<string>;
}

export interface LocationDetailsResponse {
  id: string;
  name: string;
  countryCode: string;
  state: string;
  city: string;
  address: string;
  postalCode: string;
  latitude: number;
  longitude: number;
  directions: Array<DisplayText>;
  timezone: string;
  contactPoint: string;
  contactNumber: string;
  contactEmail: string;
  tags: Array<string>;
  stations: LocationStationStatus;
  evses: LocationEvseStatus;
  activeTariffIdAc?: string;
  activeTariffIdDc?: string;
  activeTariffNameAc?: string;
  activeTariffNameDc?: string;
  emi3Name: string;
  ocpiAvailability: string;
  powerConnection?: PowerConnection;
  enableAutoRestart: boolean;
}

export interface LocationStationStatus {
  total: number;
  available: number;
  faulted: number;
  unavailable: number;
}

export const locationStationStatusEmpty: LocationStationStatus = {
  total: 0,
  available: 0,
  faulted: 0,
  unavailable: 0,
};

export interface LocationEvseStatus {
  total: number;
  available: number;
  charging: number;
  faulted: number;
  reserved: number;
  unavailable: number;
}

export interface PowerConnection {
  id: string;
  loadManagement: string;
  maxWattage: number;
}

export const powerConnectionEmpty: PowerConnection = {
  id: "",
  loadManagement: LoadManagementType.Disabled,
  maxWattage: 0,
};

export const locationEvseStatusEmpty: LocationEvseStatus = {
  total: 0,
  available: 0,
  charging: 0,
  faulted: 0,
  reserved: 0,
  unavailable: 0,
};

export const locationDetailsResponseEmpty = {
  id: "",
  name: "",
  countryCode: "",
  state: "",
  city: "",
  address: "",
  postalCode: "",
  latitude: 0,
  longitude: 0,
  directions: [],
  timezone: "",
  contactPoint: "",
  contactNumber: "",
  contactEmail: "",
  tags: [],
  fullAddress: "",
  stations: locationStationStatusEmpty,
  evses: locationEvseStatusEmpty,
  activeTariffIdAc: "",
  activeTariffIdDc: "",
  activeTariffNameAc: "",
  activeTariffNameDc: "",
  emi3Name: "",
  ocpiAvailability: "",
  powerConnection: powerConnectionEmpty,
  enableAutoRestart: false,
};

export interface LocationByIdProps {
  locationId: string | number;
}
