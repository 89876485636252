import { DisplayText } from "src/api/common/interfaces";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

export interface StationValues {
  id: string;
  locationId: string;
  ocppStationId: string;
  ocppStationPwd?: string;
  brand: string;
  model: string;
  additionalInformation: string;
  roamingEnabled: boolean;
  reservationEnabled: boolean;
  network: string;
  powerMaxWattage: number;
  emi3Name: string;
  evses: Array<EvseValues>;
  profile: StationProfileValues;
  directions?: Array<DisplayText>;
}

export interface EvseValues {
  id?: string;
  powerType: string;
  maxCurrent: number;
  maxVoltage: number;
  maxWattage: number;
  qrCodeId: string;
  isQrCodeIdRequired: boolean;
  connectors: Array<ConnectorValues>;
}

export interface ConnectorValues {
  id?: string;
  kind: string;
  format: string;
  alias?: string;
}
export interface StationProfileValues {
  enableConnectorZeroEmulation: boolean;
  useHeartbeatAsPing: boolean;
}

export const connectorInitialValues = () => ({
  id: uuidv4(),
  kind: "",
  format: "",
});

export const evseInitialValues = () => ({
  id: uuidv4(),
  powerType: "",
  maxCurrent: 0,
  maxVoltage: 0,
  maxWattage: 0,
  qrCodeId: "",
  isQrCodeIdRequired: false,
  connectors: [connectorInitialValues()],
});

export const stationInitialValues: () => StationValues = () => ({
  id: uuidv4(),
  locationId: "",
  brand: "",
  model: "",
  roamingEnabled: false,
  reservationEnabled: false,
  network: "",
  ocppStationId: "",
  ocppStationPwd: "",
  powerMaxWattage: 0,
  emi3Name: "",
  additionalInformation: "",
  evses: [evseInitialValues()],
  profile: {
    enableConnectorZeroEmulation: false,
    useHeartbeatAsPing: false,
  },
});

export const validationSchema = Yup.object().shape({
  locationId: Yup.string().required("schemaRequiredLocation"),
  brand: Yup.string().required("schemaRequiredBrand"),
  model: Yup.string().required("schemaRequiredModel"),
  network: Yup.string().required("schemaRequiredNetwork"),
  ocppStationId: Yup.string().required("schemaRequiredOcppStationId"),
  emi3Name: Yup.string()
    .required("schemaRequiredEmi3Name")
    .matches(/^[A-Z0-9]+$/),
  ocppStationPwd: Yup.string(),
  additionalInformation: Yup.string(),
  directions: Yup.array().of(
    Yup.object().shape({
      language: Yup.string().required("schemaRequiredDirectionLanguage"),
      text: Yup.string().required("schemaRequiredDirectionText"),
    }),
  ),
  evses: Yup.array().of(
    Yup.object().shape({
      powerType: Yup.string().required("schemaRequiredPowerType"),
      maxCurrent: Yup.number().positive("schemaRequiredMaxCurrent"),
      maxVoltage: Yup.number().positive("schemaRequiredMaxVoltage"),
      maxWattage: Yup.number().positive("schemaRequiredMaxWattage"),
      isQrCodeIdRequired: Yup.boolean(),
      qrCodeId: Yup.string().when("isQrCodeIdRequired", {
        is: true,
        then: (schema) => schema.required("schemaRequiredQrCodeId"),
        otherwise: (schema) => schema.optional(),
      }),
      connectors: Yup.array().of(
        Yup.object().shape({
          kind: Yup.string().required("schemaRequiredConnectorType"),
          format: Yup.string().required("schemaRequiredConnectorFormat"),
        }),
      ),
    }),
  ),
});
