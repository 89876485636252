import { axiosInstance } from "src/api";
import {
  SessionExportParams,
  SessionMetricsFilter,
  SessionMetricsResponse,
  SessionResponse,
  SessionTimeSeriesFilter,
  TimeSeries,
} from "src/api/sessions/interfaces";

export const getSessionMetrics = (params: SessionMetricsFilter) =>
  axiosInstance.get<SessionMetricsResponse>("/sessions/metrics", { params });

export const getSessionsStartedChartData = (params: SessionTimeSeriesFilter) =>
  axiosInstance.get<TimeSeries>("/sessions/time-series/sessions-started", { params });

export const getEnergyConsumptionChartData = (params: SessionTimeSeriesFilter) =>
  axiosInstance.get<TimeSeries>("/sessions/time-series/energy-consumption", { params });

export const getEvseUtilizationChartData = (params: SessionTimeSeriesFilter) =>
  axiosInstance.get<TimeSeries>("/sessions/time-series/evse-utilization", { params });

export const getSessionReport = (params: SessionExportParams) =>
  axiosInstance.get<string>("/sessions/reports", { params });

export const getSession = (id: string) => axiosInstance.get<SessionResponse>(`/sessions/${id}`);
