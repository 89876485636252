import { DisplayText } from "src/api/common/interfaces";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

export interface LocationFormValues {
  locationId: string;
  name: string;
  countryCode: string;
  state?: string;
  city: string;
  address: string;
  postalCode: string;
  contactName?: string;
  contactNumber?: string;
  contactEmail?: string;
  activeTariffIdAc?: string;
  activeTariffIdDc?: string;
  activeTariffNameAc?: string;
  activeTariffNameDc?: string;
  emi3Name: string;
  ocpiAvailability: string;
  powerConnectionId: string;
  powerConnectionLoadManagement: string;
  powerConnectionMaxWattage: number;
  directions?: Array<DisplayText>;
  enableAutoRestart: boolean;
}

export enum OcpiAvailabilityType {
  Unpublished = "UNPUBLISHED",
  Unlisted = "UNLISTED",
  Public = "PUBLIC",
}

export enum LoadManagementType {
  Disabled = "DISABLED",
  EvenDistribution = "EVEN_DISTRIBUTION",
}

export enum Iso639LanguageCode {
  English = "en",
  Thai = "th",
}

export const initialValues = (): LocationFormValues => ({
  locationId: uuidv4(),
  name: "",
  countryCode: "SG",
  city: "",
  address: "",
  postalCode: "",
  emi3Name: "",
  ocpiAvailability: OcpiAvailabilityType.Unpublished,
  powerConnectionId: uuidv4(),
  powerConnectionLoadManagement: LoadManagementType.Disabled,
  powerConnectionMaxWattage: 0,
  directions: [],
  enableAutoRestart: false,
});

const fields = {
  name: Yup.string().required("schemaRequiredLocationName"),
  countryCode: Yup.string().required("schemaRequiredLocationCountry"),
  city: Yup.string().required("schemaRequiredLocationCity"),
  address: Yup.string().required("schemaRequiredLocationAddress"),
  postalCode: Yup.string().required("schemaRequiredLocationPostal"),
  emi3Name: Yup.string()
    .required("schemaRequiredEmi3Name")
    .matches(/^[A-Z0-9]+$/),
  ocpiAvailability: Yup.string().required("schemaRequiredOcpiAvailability"),
  powerConnectionMaxWattage: Yup.number().typeError("schemaRequiredPowerConnectionMaxWattage"),
  directions: Yup.array().of(
    Yup.object().shape({
      language: Yup.string().required("schemaRequiredDirectionLanguage"),
      text: Yup.string().required("schemaRequiredDirectionText"),
    }),
  ),
};

export const validationSchema = Yup.object().shape({
  ...fields,
});
