import { getLocationSummaries } from "src/api/locations";
import { LocationSummaryFilter } from "src/api/locations/interfaces";
import { getStationTypes } from "src/api/stations";

export const fetchAndProcessLocationData = async (filter: LocationSummaryFilter) => {
  const response = await getLocationSummaries(filter);
  const locations = response.data.map((location) => ({
    id: location.id,
    countryCode: location.countryCode,
    name: location.name,
    ocpiAvailabilityType: location.ocpiAvailabilityType,
  }));

  return locations;
};

export const fetchStationComponents = async () => {
  const response = await getStationTypes();
  return response.data;
};
