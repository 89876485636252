import { useInfiniteQuery } from "@tanstack/react-query";
import { axiosInstance } from "src/api";

interface SessionsFilter {
  tenantId: string;
  locationId?: string;
  startTimeFrom?: string;
  startTimeTo?: string;
  pageToken?: string;
  pageSize?: number;
}

interface SessionSummaryResponse {
  id: string;
  startTime: string;
  endTime?: string;
  locationId: string;
  locationName: string;
  ocppStationId: string;
  initiatedBy: string;
  wattHour: number;
  status: string;
  cost: string;
  tariffId?: string;
  tariffName?: string;
  currency?: string;
}

interface SessionsSummaryResponse {
  items: SessionSummaryResponse[];
  nextPageToken?: string;
}

const useGetSessions = (filters: SessionsFilter) =>
  useInfiniteQuery({
    queryKey: ["sessions", filters],
    queryFn: ({ pageParam }) =>
      axiosInstance.get<SessionsSummaryResponse>("/sessions/all", {
        params: {
          ...filters,
          pageToken: pageParam || undefined,
        },
      }),
    initialPageParam: "",
    getNextPageParam: (lastPage) => lastPage.data.nextPageToken,
  });

export default useGetSessions;
