import { Card, CardContent, Grid, Stack, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SessionResponse } from "src/api/sessions/interfaces";

export interface SessionInfoProps {
  session: SessionResponse;
  hasIdlingFee: boolean;
}

interface LabelValue {
  label: string;
  value: string;
}

const SessionInfoItem: FC<LabelValue> = ({ label, value }) => {
  const { palette } = useTheme();
  return (
    <Grid item container key={`session-item-${label}`}>
      <Grid item xs={6}>
        <Typography variant="h6" color={palette.primary.dark}>
          <FormattedMessage id={label} />
        </Typography>
      </Grid>
      <Grid item xs>
        <Typography variant="body2" color={palette.grey[500]}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

const SessionInfo: FC<SessionInfoProps> = ({ session, hasIdlingFee }) => {
  const { palette } = useTheme();
  const intl = useIntl();

  const formatNullableString = (input?: string) => (input && input !== "" ? input : "-");

  const formatTotalIdlingCost = () => {
    if (!hasIdlingFee) {
      return "NA";
    }
    const parkingCost = session.parkingCost ?? 0;
    return intl.formatNumber(parkingCost, {
      style: "currency",
      currency: session.currency ?? "SGD",
      currencyDisplay: "narrowSymbol",
    });
  };

  const formatIdlingDurationCharged = () => {
    if (!hasIdlingFee) {
      return "NA";
    }
    const duration = session.parkingTime ?? "PT0S";
    const d = dayjs.duration(duration);
    if (d.asSeconds() <= 0) {
      return "-";
    }
    return d.format("HH:mm:ss");
  };

  const formatDatetimeString = (input?: string) => {
    if (!input) return "-";
    return dayjs(input).format("L LT");
  };

  const gridLabelValues: { [key: string]: LabelValue[] } = {
    leftColumn: [
      {
        label: "startDateAndTime",
        value: formatDatetimeString(session.startTime),
      },
      {
        label: "endDateAndTime",
        value: formatDatetimeString(session.endTime),
      },
      {
        label: "stationLocation",
        value: `${session.locationName}`,
      },
      {
        label: "evseID",
        value: formatNullableString(session.evseEmi3Id),
      },
      {
        label: "initiatedBy",
        value: formatNullableString(session.initiatedBy),
      },
      {
        label: "stopReason",
        value: formatNullableString(session.stopReason),
      },
    ],
    rightColumn: [
      {
        label: "status",
        value: `${session.status}`,
      },
      {
        label: "tariff",
        value: formatNullableString(session.tariffName),
      },
      {
        label: "totalEnergyConsumedKwh",
        value: `${session.wattHour / 1000}`,
      },
      {
        label: "totalChargingCost",
        value: intl.formatNumber(session.energyCost ?? 0, {
          style: "currency",
          currency: session.currency ?? "SGD",
          currencyDisplay: "narrowSymbol",
        }),
      },
      {
        label: "totalIdlingCost",
        value: formatTotalIdlingCost(),
      },
      {
        label: "totalIdlingDurationCharged",
        value: formatIdlingDurationCharged(),
      },
    ],
  };

  return (
    <Card>
      <CardContent>
        <Stack>
          <Typography variant="h3">
            <FormattedMessage id="sessionID" />
          </Typography>
          <Typography variant="body2" color={palette.grey[500]} paddingBottom={3}>
            {session.id}
          </Typography>

          <Card style={{ backgroundColor: "#F0F3F6" }} elevation={0}>
            <CardContent>
              <Grid container display="flex" flexDirection={{ xs: "column", lg: "row" }} rowSpacing={2}>
                {Object.keys(gridLabelValues).map((key) => (
                  <Grid container item rowSpacing={2} key={`session-col-${key}`} xs={12} lg={6}>
                    {gridLabelValues[key].map((item) => SessionInfoItem(item))}
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default SessionInfo;
